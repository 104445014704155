











































































































































































import { Component, Prop, Vue, Model, Watch } from "vue-property-decorator";
import {
  ExternalProjectBudgetItemDto,
  ExternalProjectCreateOrUpdateDto,
  ExternalProjectSpecificPlanDto,
} from "@/api/appService";
import AbSelect from "@/components/AbSelect/index.vue";
import AbCard from "@/components/AbCard/index.vue";
import api from "@/api";
@Component({
  name: "BudgetItems",
  components: {
    AbSelect,
    AbCard,
  },
})
export default class BudgetItems extends Vue {
  @Prop({ required: true })
  budgetForm!: ExternalProjectCreateOrUpdateDto;

  @Prop({ required: false, default: 0 })
  projectId!: number;

  @Prop({ required: false, default: false })
  readonly!: number;

  // @Prop({required:true})
  // value!:ExternalProjectBudgetItemDto;

  @Prop({ required: true })
  specificPlans!: any;

  form: ExternalProjectCreateOrUpdateDto = {};

  // 项目预算种类 一级
  projectBudgetCategoryList = [];

  // 项目预算种类 二级
  projectBudgetTypeList = [];

  // 资金来源
  projectBudgetCapitalSourceList = [];

  @Watch("budgetForm", { deep: true })
  getForm(newValue: ExternalProjectCreateOrUpdateDto) {
    this.form = newValue;
  }
  @Watch("form", { immediate: true, deep: true })
  getChangeForm(newValue: any) {
    this.$emit("change", newValue);
  }

  created() {
    this.fetchEnum();
    this.form = this.budgetForm;
  }

  fetchEnum() {
    api.enumService
      .getValues({ typeName: "ProjectBudgetCategory" })
      .then((res: any) => {
        this.projectBudgetCategoryList = res;
      });
    api.enumService
      .getValues({ typeName: "ProjectBudgetType" })
      .then((res: any) => {
        this.projectBudgetTypeList = res;
      });
    api.enumService
      .getValues({ typeName: "ProjectBudgetCapitalSource" })
      .then((res: any) => {
        this.$nextTick(() => {
          this.projectBudgetCapitalSourceList = res;
        });
      });
  }

  changeSpecificPlans(e: any, scope: any) {
    console.log(this.specificPlans, "specificPlans");
    if (this.specificPlans && this.specificPlans!.length > 0) {
      this.specificPlans!.map((item: any, index: number) => {
        if (e == item.id) {
          scope.projectSpecificGoalId = item.projectSpecificGoalId;
          scope.itemName = item.title;
        }
      });
    }
  }

  // 预算报价
  getBudgetQuotation(index: number, item: ExternalProjectBudgetItemDto) {
    this.form!.budgetItems![index].budgetQuotation =
      Math.round(Number(item.unitPrice ?? 0) * Number(item.number ?? 0) * 100) /
      100;
    this.getMoney();
  }
  // 合计
  async getMoney() {
    let applyNum = 0;
    let selfNem = 0;
    let otherNum = 0;
    this.form!.budgetItems!.map((budgetItem, index) => {
      switch (budgetItem.projectBudgetCapitalSource) {
        case "Apply":
          applyNum +=
            Math.round(Number(budgetItem!.budgetQuotation ?? 0) * 100) / 100;
          break;
        case "Self":
          selfNem +=
            Math.round(Number(budgetItem!.budgetQuotation ?? 0) * 100) / 100;
          break;
        case "Other":
          otherNum +=
            Math.round(Number(budgetItem!.budgetQuotation ?? 0) * 100) / 100;
          break;
      }
    });
    this.form!.applyMoney = Number(applyNum.toFixed(2));
    this.form!.selfMoney = Number(selfNem.toFixed(2));
    this.form!.otherMoney = Number(otherNum.toFixed(2));
    this.form!.totalMoney =
      Math.round(
        (Number(this.form!.applyMoney ?? 0) +
          Number(this.form!.selfMoney ?? 0) +
          Number(this.form!.otherMoney ?? 0)) *
          100
      ) / 100;
  }

  // 添加项目团队信息
  private addBudgetItemsRow(index: number) {
    const budgetItem: ExternalProjectBudgetItemDto = {
      projectBudgetCategory: undefined,
      projectBudgetType: undefined,
      itemName: undefined,
      projectSpecificPlanId: undefined,
      projectSpecificGoalId: undefined,
      content: undefined,
      unitPrice: undefined,
      number: undefined,
      unit: undefined,
      budgetQuotation: undefined,
      projectBudgetCapitalSource: undefined,
      projectId: this.projectId,
    };
    this.form!.budgetItems!.splice(index + 1, 0, budgetItem);
  }

  // 删除项目团队信息
  private deleteBudgetItemsRow(index: number) {
    if (this.form.budgetItems!.length === 1) {
      return;
    }
    this.form.budgetItems!.splice(index, 1);
    this.getMoney();
  }
}
