




















import {
  Component,
  Vue,
  Inject,
  Prop,
  Watch,
  Model,
} from "vue-property-decorator";
import api from "@/api";
import { OrganizationStatus, OrganizationUnitDto } from "@/api/appService";

@Component({
  name: "organizationSelect",
})
export default class organizationSelect extends Vue {
  @Model("change", { required: true, default: "" })
  readonly value!: number | string;

  @Prop({ required: false, default: false })
  clearable!: boolean;

  @Prop({ required: false, default: "请选择机构查询" })
  placeholder!: string;

  @Prop({ required: false, default: false })
  disabled!: boolean;

  @Prop({ required: false, default: 0 })
  organizationType!: number;

  @Prop({ required: false, default: false })
  bindValueName!: boolean;

  @Prop({ required: false, default: false })
  multiple!: boolean;

  private val: number | string = "";
  partnerOrgList: OrganizationUnitDto[] = [];

  @Watch("value", { deep: true })
  valueChange(newVal: string | number) {
    this.val = newVal;
  }

  @Watch("val")
  onValueChange(newVal: string | number) {
    this.$emit("change", newVal);
  }

  created() {
    this.val = this.value;
    this.fetchData();
  }

  fetchData() {
    api.organizationUnit
      .getAll({
        organizationType: this.organizationType,
        maxResultCount: 10000,
        status: OrganizationStatus.Approved,
      })
      .then((res: any) => {
        this.partnerOrgList = res.items;
      });
  }
}
