






























































































































































import api from "@/api"; //ABP API接口
import { Vue, Component, Ref, Watch } from "vue-property-decorator";

import {
  OrganizationUnitCreateOrUpdateDto,
  PartnerCapitalApplyDto,
  AuditFlowScope,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import OrganizationSelect from "@/components/OrganizationSelect/index.vue";

@Component({
  name: "RoleList",
  components: {
    PagedTableView,
    AbSelect,
    AgileAuditTag,
    OrganizationSelect,
  },
})
export default class PartnerCapitalApplyList extends Vue {
  @Ref() readonly pagedTableView!: any;
  type = "internal";
  queryForm = {
    displayName: "",
    orgName: "",
    isInternal: false,
    startDate: "",
    endDate: "",
  };

  @Watch("$route.params")
  changeRoute(newValue: any) {
    this.queryForm.isInternal = this.$route.params.type === "internal";
    console.log("type=" + this.$route.params.type);
    this.pagedTableView!.fetchData();
  }

  // resetForm() {
  //   this.queryForm.displayName = "";
  //   this.queryForm.orgName = "";
  //   this.queryForm.isInternal = this.$route.params.type === "internal";
  //   this.queryForm.startDate = "";
  //   this.queryForm.endDate = "";
  //   this.pagedTableView!.fetchData();
  // }

  created() {
    // type internal=内部项目
    this.queryForm.isInternal = this.$route.params.type === "internal";
  }

  // 获取表数据
  fetchData(params: any) {
    params.isInternal = this.$route.params.type === "internal";
    return api.partnerCapitalApply.getAll(params);
  }

  // 新建
  handleCreate() {
    this.$router.push({
      name: "partnerCapitalApplyCreate",
      query: {
        type: this.queryForm.isInternal ? "internal" : "",
      },
    });
  }

  // 编辑
  handleEdit(index: number, row: any) {
    this.$router.push({
      name: "partnerCapitalApplyEdit",
      params: {
        id: row.id,
      },
      query: {
        type: this.queryForm.isInternal ? "internal" : "",
      },
    });
  }

  handleMenu(e: any) {
    switch (e.type) {
      case "detail": {
        this.jumpDetail(e.index, e.item);
        return;
      }
      case "edit": {
        this.handleEdit(e.index, e.item);
        return;
      }
      case "delete": {
        this.handleDelete(e.index, e.item);
        return;
      }
      case "startAudit": {
        this.handleStartAudit(e.index, e.item);
        return;
      }
      case "auditFlow": {
        this.handleAuditFlow(e.index, e.item);
        break;
      }
    }
  }

  // 查看详情
  jumpDetail(index: number, row: PartnerCapitalApplyDto) {
    this.$router.push({
      name: "partnerCapitalApplyDetail",
      params: { id: row.id!.toString() },
    });
  }

  // 提交审核
  async handleStartAudit(
    item: number,
    node: OrganizationUnitCreateOrUpdateDto
  ) {
    // await api.partnerCapitalApply.startAudit({
    //   body: {
    //     id: node.id
    //   }
    // });
  }

  // 删除
  async handleDelete(index: number, row: OrganizationUnitCreateOrUpdateDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.partnerCapitalApply.delete({ id: row.id }).then((res) => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    });
  }

  handleAuditFlow(index: number, row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.PartnerCapitalApply,
        hostId: row.id,
        auditFlowId: row.auditFlowId,
      },
    });
  }
}
