







































































import { Component, Ref, Vue } from "vue-property-decorator";
import { AuditFlowScope, PartnerCapitalApplyDto } from "@/api/appService";
import api from "@/api";
import moment from "moment";
import ExportWord from "@/components/DownloadWord/index.vue";

import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";

@Component({
  name: "partnerCapitalApplyDetail",
  components: {
    ExportWord,
    AuditBlock,
  },
})
export default class PartnerCapitalApplyDetail extends Vue {

  dataId?: number;
  detail: PartnerCapitalApplyDto = {};
  styles = "";
  selector = "table-detail-world";
  filename = "合作方资金申请表";

  created() {
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      this.fetchDetail();
    }
  }

  // 获取详情
  async fetchDetail() {
    await api.partnerCapitalApply.get({ id: this.dataId }).then((res) => {
      this.detail = { ...res };    });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }


  get auditApi(){
    return {
      canAudit: api.partnerCapitalApply.canAudit,
      userAudit: api.partnerCapitalApply.audit
    } as AuditApi
  }

}
